<template>
  <f7-page class="article-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <CommonNavigationComponent type="back" :title="$t.getTranslation('LBL_DISCOUNT')" />
    </template>

       <div class="notifications">
        
        <template v-if="promotionsList && promotionsList.length > 0">
          <ul id="promolist">
            <li  v-for="promotion in promotionsList" :key="'prm_' + promotion.Name" @click="cartPage()">
             <div class="disc-card">
                <div class="sub-ticket">
                  <div class="left-dot"></div>
                </div>
                <div class="main-ticket" :style="{ 'background-image': 'url(' + require('@/assets/images/coupons/bg-coupon.png') + ')'}">
                  {{  promotion.Name }} <br>
                  {{ $t.getTranslation("LBL_START") }}: {{ $h.formatDateTime(promotion.StartDate )}} <br/>
                  {{ $t.getTranslation("LBL_END") }}: {{ $h.formatDateTime(promotion.EndDate )}}
                  <div class="right-dot"></div>
                </div>
              </div>
            </li>
          </ul>
        </template>

        <template v-else>
          <NoDataFoundComponent :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_DISCOUNT_FOUND')" />
        </template>
      </div>

       

       

  </f7-page>
</template>

<script>
import { defineComponent, ref, inject, onMounted, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import CommonNavigationComponent from "@/components/navigations/CommonNavigationComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CommonNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "DiscountsPage",
  components: {
    CommonNavigationComponent,
    NoDataFoundComponent
  },
  props: { f7router: Object, f7route : Object },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");

    let page = 1;
    let size = 30;
    let lastPage = 1;

    const showPreloader = ref(false);
    const promotionsList = ref([]);

    const getPromotionsDiscount = async () => {
      let ret = await get("/promotion/list", { page: page, size: size, LanguageCode: $t.getLanguage() });
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          promotionsList.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      if (page >= lastPage) {
        showPreloader.value = false;
      }
      return lastPage >= page ? getPromotionsDiscount() : false;
    };

    const cartPage = () => {
      props.f7router.navigate({ name: "cartPage", params: { } });
    }

    onMounted(() => {
      getPromotionsDiscount();
    });

    return { promotionsList, cartPage, showPreloader  };
  }
  
});


</script>
<style>
.article-container {
  margin-top: 0px;
}
.article-card .image {
  margin-top: 0px;
}

.content{
  text-align: center;
  padding: 50px 20px 0;
}
.disc-card{
  display: flex; 
  margin-top: 30px;
  width: 100%;
}
.main-ticket{
  flex: 1; 
  display: flex; 
  align-items: center; 
  padding: 20px; 
  color: #fff;
  background-repeat: repeat;
}
.sub-ticket{
  width: 50px; 
  background: #eaeaea; 
  border-right: 2px dashed #fff; 
  align-items: center; display: flex; 
  background: #382d3e;
}
.left-dot{
  margin-left: -10px; 
  width: 20px; 
  height: 20px; 
  background: #fff; 
  border-radius: 100%;
}
.right-dot{
  position: absolute; 
  right: -10px; 
  width: 20px; 
  height: 20px; 
  background: #fff; 
  border-radius: 100%;
}

a.link.ticket-title {
    color: #fff;
}

</style>
